import React from "react"
import Layout from "../components/layout"
import Header from "../components/section/header"
import Seo from "../components/seo"

export default function about({ pageContext: { data }, location }) {
  const about = data
  return (
    <Layout>
      <Seo title={about.frontmatter.title} pathname={location.pathname} />
      <Header title={about.frontmatter.title} />
      <Content data={about.html} />
    </Layout>
  )
}

const Content = ({ data }) => {
  return (
    <section
      className="max-w-5xl w-11/12 2xl:w-full text-black mx-auto py-6 flex flex-col text-left prose md:prose-lg prose-h1:text-3xl prose-h1:font-serif"
      dangerouslySetInnerHTML={{ __html: data }}
    />
  )
}
